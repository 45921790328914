
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none;
  color: #e8d207;
}

a:hover {
  color: #282c34;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.column-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: start;
  align-items: center;
}


.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.font-14{
  font-size: 14px;
}

.relative{
  position: relative;
}

.align-items-center{
  align-items: center !important;
}

.w-70{
  width: 80% !important;
}

.ant-table-body{
  min-height: 40vh !important;
}

.ant-modal-body .ant-btn-primary{
  background-color: #e8d207 !important;
  color: white !important;
}

.ant-modal-body .ant-btn-primary:hover{
  background-color: #fff387 !important;
  color: #bba90b !important;
}

.ant-modal-body .ant-btn-default{
  border-color: #e8d207 !important;
}

.ant-modal-body .ant-btn-default:hover{
  color: #e8d207 !important;
}

.w-45{
  width: 45% !important;
}

.text-white{
  color: whitesmoke;
}


.absolute{
  position: absolute !important;
}

.z-index-2{
  z-index: 2;
}

.flex-end{
  display: flex;
  justify-content: end;
}

.text-center{
  text-align: center;
}

.ant-btn-secondary {
  color: white !important;
  background-color: #233c69;
}

.ant-btn-secondary:hover{
  color: #282c34 !important;
  background-color: #cadafa;
}