/* @import url('https://fonts.googleapis.com/css2?family=Young+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&display=swap'); */

.Tittle{
    /* font-family: 'Young Serif', serif; */
    font-size: 36px;
    margin: 10px 0;
}


.image_box{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    width: auto;
    height: auto;
    margin: 0 15px;
    justify-content: center;
}

.admin_container{
    /* border: solid 2px #e8d207;; */
    border-radius: 30px;
    padding: 32px;
    position: absolute;
    top: 30%;
}
.renderAva{
    display: flex;
    width: 180px;
    height: 180px;
}
.edit-img {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    padding: 2px 6px;
    background-color: #d5d5d5;
    position: absolute;
    transform: translate(100%, 140%);
  
    &:hover {
      background-color: #949292;
    }
  }

.Tittle_Element{
    font-size: 23px;
    line-height: 36px;
    width: 100%;
}
.TE_Input{
    margin-bottom: 16px;
    width: 100%;
    line-height: 36px;
    border: none;
    font-size: 18px;
    font-family: "font-family: 'Poppins', sans-serif;";
}


.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }
.admin_control{
    display: flex;
    justify-content: end;
    padding-right: 2rem;
}

.ant-dropdown-trigger{
    display: flex;
    align-items: center;
}

#profile .ant-form-item-label label{
    color: white;
}

#profile .ant-input, #profile .ant-input-number, #profile .ant-select-selector, #profile .ant-input-password{
    background-color: transparent !important;
    color: #e8d207 !important;
    border-color: #d9d9d978 !important;
}

#profile .ant-input-number-input{
    color: #e8d207 !important;
}

.admin_name{
    color: #e8d207;
}

.admin_role{
    color: white;
    text-align: center;
     margin-top: 5px;
}

#profile .anticon{
    color: #e8d207 !important;
}

.ant-layout-sider{
    background-color: rgb(252, 249, 221) !important;
}

.ant-menu-item{
    color: #12141b !important;
}

.ant-menu{
    background-color: #f5efb3 !important;
   
}

.ant-menu .anticon{
    color: #12141b !important;
}

.ant-menu-item-active{
    background-color: #e8d207 !important;
    color: #12141b  !important;
}

.ant-menu-item-selected{
    background-color: #354759 !important;
    color: white !important;
}

.ant-menu-item-selected .anticon{
    color: white !important;
}

.Profile_Container{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    border: solid 2px;
    border-radius: 30px;
    padding: 15px;
    width: auto;
    height: auto;
    margin: 0 15px;
    border-color: #e8d207;
    justify-content: center;
}

.ant-picker-status-error{
    background-color: transparent !important;
}

#profile ::placeholder {
    color: white !important;
}


